import request from "@/request";
import { ResultCommon } from "@/types/common";
import {
  QueryOrderData,
  QueryBillData,
  ChangeNickNameData,
  QueryRewardsData,
  GiftSendCodeData,
  CheckNumberCodeData,
  ResendCodeData,
  CheckDoneCodeData,
  SubmitGiftData,
  CheckGiftTimeLimitData,
  QueryChannelInfoData,
  OrderListResultType,
} from "@/types/userCenter";

// 查询用户已购服务列表
export const queryServiceLists = (): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-product/user/have/product",
  });
};

// 查询订单明细列表
export const queryOrderLists = (
  data: QueryOrderData
): Promise<OrderListResultType> => {
  return request.post<OrderListResultType>({
    url: "/v-product/user/paid/order/pages",
    data,
  });
};

// 查询账单明细列表
export const queryBillLists = (data: QueryBillData): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-account/subAccountHistory/list",
    data,
  });
};

// 查询积分明细列表
export const queryRewardsLists = (
  data: QueryRewardsData
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-account/scoreAccountHistory/list",
    data,
  });
};

// 修改用户昵称
export const changeNickName = (
  data: ChangeNickNameData
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/reset/nickname",
    data,
  });
};

// 修改用户昵称
export const queryChannelInfo = (
  data: QueryChannelInfoData
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/channel/info",
    data,
  });
};

/**
 * 赠送服务相关
 */
export const giveService = {
  getGiftSendCode: (data: GiftSendCodeData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/send/msg/code",
      data,
    });
  },
  checkNumberCode: (data: CheckNumberCodeData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/check/verification/code",
      data,
    });
  },
  getResendCode: (data: ResendCodeData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/resend/verification/code",
      data,
    });
  },
  checkDoneCode: (data: CheckDoneCodeData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/check/donee/msg/code",
      data,
    });
  },
  submitGift: (data: SubmitGiftData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/check/save",
      data,
    });
  },
  checkGiftTimeLimit: (data: CheckGiftTimeLimitData): Promise<ResultCommon> => {
    return request.post<ResultCommon>({
      url: "/v-user/gift/check",
      data,
    });
  },
};
